<template>
    <div data-component="">
        <layout-pc v-if="$responsive.is('pc')" :items="items"></layout-pc>
        <layout-mobile v-if="$responsive.is('mobile')" :items="items"></layout-mobile>
    </div>
</template>


<script>
    import LayoutPC from './layout-pc';
    import LayoutMobile from './layout-mobile';
    import TimeUnit from '../../libs/cache-installer/time-unit';

    export default {

      components: {
        'layout-pc': LayoutPC,
        'layout-mobile': LayoutMobile,
      },

      data() {
        return {
          items: [],
        };
      },

      created() {
        this.$cache.defineValueProvider('layout.items', this.loadLayoutItems, 2, TimeUnit.Hours);
      },

      mounted() {
        this.$cache.get('layout.items').then(items => {
          this.items = items;
        });
      },

      methods: {

        loadLayoutItems() {
          return new Promise((resolve, reject) => {
            this.$ajax.get('/item/home').then(response => {
              if (response.code === 100) {
                resolve(response.data);
              } else {
                reject(response);
              }
            }).catch(reason => {
              reject(reason);
            });
          });
        }

      }

    }
</script>